.Alert {
    margin-top: 1px;
    text-align: center;
}

.formDescida {
    border-radius: 16px;
    background-color: #f2efef;
    padding: 0 100px 0 80px;

}

.formError {
    color: red;
    background-color: rgb(229, 170, 170);
    border-radius: 10px;
}

.formLine {
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: right;
    margin-top: 16px;
}



.noEditable {
    background-color: rgb(232, 230, 230);
}



section {
    padding: 10px;
    border-radius: 15px;
    background-color: rgb(236, 233, 229);

}



input {
    padding: 0px;
    border: 1px;
    border-radius: 10px;
    width: 150px;
}

.mb-3 {
    background-color: yellow;
}

.row {
    display: flex;
    vertical-align: middle;
    padding-bottom: 8px;
}