.container {
    margin-top: 10%;
    margin-bottom: 300px;
    width: 90%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}



.formArea {
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 55%;
    margin: 16px;
    
    align-items: center;
    text-align: center;
    background-color: rgb(243, 239, 235);
    border-radius: 16px;
}

.formLogin {
    display: flex;
    flex-direction: column;
    text-align: center;
    
}

img {
    width: 128px;
    height: 128px;
}



.inputText {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    width: 400px;
    text-align: center;
    
}


.inputText input {
    border-radius: 8px;
    margin-top: 16px;
    width: 400px;
    margin-right: 0;
    margin-left: 24px;
}

.Button {
    margin-top: 16px;
    margin-left: 50%;
}