

.pagination {
    margin-left: 40%;
    margin-right: 40%;
    width: 20%;
    text-align: center;
    align-items: center;
}

.msgUsuario {
    width: 30%;
    margin: 0 35% 0 35%;
}

.formArea {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    background-color: #f8f9fa;
    margin: 64px auto 64px auto;
    width: 60%;
    font-size: 20px;
    border-color: black;
    border-radius: 16px;
}
   


.formPesquisaPlaca {
    margin-top: 18px;
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    width: 60%;
    height: 80px;
    
}



label {
    width: 90px;

}

.pagination {
    align-items: center;
    text-align: center;
}

.modal {
    height: 350px;
    width: 400px;
}

.inputForm {
    width: 60%;
    height: 40px;
    text-align: center;
    border: solid 1px black;
    margin-right: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 64px bold;
}

