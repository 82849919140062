img {
    height: 70px;

}

.userInfo {
    position:absolute;
    right: 5px;
    
}

