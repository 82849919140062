.noEditable {
    background-color: rgb(232, 230, 230);
  
}

.form-control {
    background-color: rgb(232, 230, 230);

}


.Alert {
    margin-top: 16px;
    text-align: center;
}

.formEntrada {
    border-radius: 16px;
    padding: 0 100px 0 80px;

}

form {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.formArea {
   

    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 32px;
    width: 100%;
    padding-right: 36px;
}

.formLine {
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: right;
    margin-top: 16px;
}

.formLine:nth-child(1) {
    margin-top: 16px;
}

.formLineButton{
    margin-top: 64px;
    margin-bottom: 16px;
    text-align: center;
   margin-left: 30px;
   margin-right: 90px;
   padding-right: 0px;

}

.buttonFormEntrada {
    text-align: center;
    margin-top: 16px;
    margin-left: 230px;
    width: 180px;
    
}



@media screen and (max-width: 750px) {
    form {
        width: 100%;
        margin-left: 2%;
        margin-right: 2%;
    }
    
    
}