.formArea {
    display: flex;
    height: 100px;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background-color: #f8f9fa;
    margin: 32px auto 32px auto;
    width: 60%;
    border-radius: 16px;
}

.form {
    width: 60%;
    font-size: 20px;
    border-color: black;
    border-radius: 16px;
}

.inputForm {
    width: 50%;
    height: 40px;
    text-align: center;
    border: solid 1px black;
    margin-right: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 64px bold;
}