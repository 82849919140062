#searchArea{
    width: 100%;

}

#divBusca{
    margin: 0 auto;
    border-radius:12px;
    width:400px;
  }
  
  #txtBusca{
    float:left;
    background-color:rgb(220, 218, 218);
    padding-left:5px;
    font-style:italic;
    font-size:18px;
    border:none;
    height:32px;
    width:260px;
  }

  #filtroArea{
  }

  #headerTable{
    display: block;
    
  }

  #imgGas {
    height: 30px;
    width: 30px;
    right: 0px;
    text-align: end;

  }

  #aside {
    background-color: aqua;
    width: 300px;
    height: 300px;
  }