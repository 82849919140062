.formArea {
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    margin-bottom: 64px;
    margin-top: 64px;
    height: max-content;
    width: 50%
}

.formLine {
    padding: 8px;
    display: flex;
    flex-direction: row;

}

.formInput {
    border-radius: 8px;
}

.formButton {
    width: 10px;
    padding-right: 10px;
    align-items: center;
    margin-top: 16px;
    margin-left: 200px;
}

.formButton form {
    background-color: aqua;
    width: 10px;
}

.Alert {
    background-color: aqua;
    height: 12px;
}

.errorWarning{
    height: 12px;
}