.rodape {
    text-align: center;
    bottom: 0;
    background-color: #f8f9fa;
    width: 100%;
}

.rodape p {
    margin-bottom: 0px;
}

