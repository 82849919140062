.titulo {
    font-size: 25px;
}

.Alert {
    margin-top: 1px;
    text-align: center;
}

.formVeiculo {
    border-radius: 16px;
    background-color: #f2efef;
    padding: 0 100px 0 80px;

}

.formArea {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 32px;
    width: 450px;
    padding-right: 36px;
}

.formLine {
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: right;
    margin-top: 16px;
}



.formLineButton{
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
   margin-left: 160px;
   margin-right: 90px;
   padding-right: 0px;

}

.buttonFormDescida {
    text-align: center;
    justify-content: center;
    padding-right: 390px;
    margin-top: 164px;
    width: 80px;
    
}

